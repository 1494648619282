const cventConfig = {
  exhibitorApplicationUrl: 'https://cvent.me/3DeQyl?rt=sxfoe1YpU0mywoOTmBz8dw', // rt-slc link was redirecting for some reason
  callForContentUrl: 'https://www.cvent.com/c/abstracts/1b1361dd-70ec-46ab-82d0-7db5d01b5f3c',
  registrationUrl:
    'https://rootstech-slc.familysearch.org/154vra?rt=sxfoe1YpU0mywoOTmBz8dw&RefId=Registration+landing+page',
  previewSchedule:
    'https://rootstech-slc.familysearch.org/event/937e1fd3-643a-4e3f-9550-e698c82776ea/websitePage:30716a17-5b17-440c-a1c5-28984d5dcae3?RefId=Agenda',
  scheduleUrl: 'https://cvent.me/gDekAG',
}

export default cventConfig
