import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { css } from '@emotion/core'
import { colors } from '@fs/zion-ui'
import { Link } from '@fs/zion-router'
import { i18n } from '@fs/zion-locale'
import churchLinks from './churchLinks'
import { legalConfig } from '../../config'

const { termsOfUseDate } = legalConfig

const legalCss = (centered) => css`
  color: ${colors.transparent.gray70};
  font-size: 12px;
  line-height: 1.33;
  ${centered ? 'text-align: center;' : ''}
`

const legalLinkCss = css`
  &,
  &:visited,
  &:active,
  &:focus {
    color: ${colors.gray100} !important;
    text-decoration: none;
  }
  &:hover {
    color: ${colors.gray100};
    text-decoration: underline;
  }
`

export default function LegalText({ centered = true, hideExtraText = false, referenceNumber, ...props }) {
  const [t] = useTranslation()
  const year = new Date().getFullYear()
  const churchLink = churchLinks[i18n.language] ? churchLinks[i18n.language] : churchLinks.en

  return (
    <div css={legalCss(centered)} {...props}>
      <div>
        {referenceNumber && <>{referenceNumber}. </>}
        <Trans i18nKey="footer.terms-of-use" values={{ termsOfUseDate }}>
          <Link external to="/legal/terms" css={legalLinkCss} linkName="Footer: Terms of Use" target="_blank">
            FamilySearch Terms of Use
          </Link>
          &nbsp; (Updated {termsOfUseDate})
        </Trans>
        &nbsp;|&nbsp;
        <Trans i18nKey="footer.privacy-notice">
          <Link external to="/privacy" css={legalLinkCss} linkName="Footer: Privacy Notice" target="_blank">
            Privacy Notice
          </Link>
        </Trans>
      </div>
      {!hideExtraText && (
        <div>
          {t('footer.copyright-notice', '© { year } by Intellectual Reserve, Inc. All rights reserved.', { year })}{' '}
          <Trans i18nKey="footer.service-by-church.link">
            A service provided by&nbsp;
            <Link to={churchLink} css={legalLinkCss} linkName="Footer: Service Provided By" external target="_blank">
              The Church of Jesus Christ of Latter-day Saints
            </Link>
          </Trans>
        </div>
      )}
    </div>
  )
}
