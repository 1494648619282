const searchConfig = {
  years: ['2019', '2020', '2021', '2022', '2023', '2024', '2025'],
  siteSearchKeys: {
    creators: 'f0',
    language: 'f1',
    publicationYear: 'f2',
    tags: 'f3',
    contentType: 'f4',
    sessionContentType: 'f5',
    exhibitors: 'f6',
    conferenceYear: 'f7',
    countryTags: 'f8',
    sessionDifficulty: 'f9',
    sessionLocation: 'f10',
  },
  contentTypes: {
    exhibitor: '6df34a33-11b0-33e2-994b-ad547dda06a1',
    creator: 'b8a1b4ae-3cf1-348b-aee1-4b07f5fa6aaf',
    session: {
      activity: '151a6ba1-c6e7-3742-a66d-3e43d281ab58',
      class: '5d9da464-7dc5-308e-898d-c27abd8a9fe8',
      companyIntro: 'bcfa22ff-ad05-3331-ab11-67817cd2f2de',
      discussion: 'ca4f9bf9-ab4a-367e-b777-ebe74db6f589',
      heritage: '0fad5d97-fdd7-35a4-8985-6881addbdfd2',
      inspirationalIdea: '0fa7c4d4-7cf8-31a4-81aa-ad38d968c398',
      keynote: '4edf9148-6cc8-3225-92d5-51c6007fe354',
      productDemo: '8638e49f-7785-3d41-a857-74e3d316894a',
      seriesEpisode: '470d4f03-d0eb-395b-883b-27fd668c5008',
      series: 'seriesSessionContentTypeId',
      tipsAndTricks: '0adf7b8f-24b7-31fb-9211-75b8813efb19',
      virtualTour: '601dcf53-22a2-3aa7-99f5-9135cccd39f0',
      workshop: '630d8948-714c-3aa0-911e-2bd53df75c9b',
    },
  },
  numResults: 10,
  languageWhiteList: [
    'ar-AE',
    'ase-US',
    'bg-BG',
    'ch-GU',
    'cs-CZ',
    'da-DK',
    'de-DE',
    'en-US',
    'es-ES',
    'et-EE',
    'fi-FI',
    'fj-FJ',
    'fr-FR',
    'hu-HU',
    'hy-AM',
    'id-ID',
    'it-IT',
    'ja-JP',
    'kek-GT',
    'kk-KZ',
    'km-KH',
    'ko-KR',
    'lt-LT',
    'lv-LV',
    'mg-MG',
    'mn-MN',
    'ms-MY',
    'ne-IN',
    'nl-NL',
    'no-NO',
    'pl-PL',
    'pon-FM',
    'pt-BR',
    'ru-RU',
    'sk-SK',
    'sm-WS',
    'sv-SE',
    'sw-KE',
    'th-TH',
    'tl-PH',
    'to-TO',
    'uk-UA',
    'vi-VN',
    'zh-CN',
    'zh-TW',
  ],
}

export default searchConfig
