import React from 'react'
import { useTranslation } from 'react-i18next'
import { PillButton } from './buttons'

export default function SessionFeedbackButton({ onFeedbackClick, fullWidth = false, disabled = false }) {
  const [t] = useTranslation()
  return (
    <PillButton
      type="button"
      onClick={onFeedbackClick}
      linkName="Session Feedback Button"
      data-testid="session-feedback-button"
      fullWidth={fullWidth}
      disabled={disabled}
      emphasis="medium"
    >
      {t('session-feedback.action', 'Session Feedback')}
    </PillButton>
  )
}
