import { ConferencePromo, MinimumCreatorDetail } from './ConferencePromo'
import Agreement from './Agreement'
import CategoryDataReference from './CategoryDataReference'
import AttachmentDetail from './AttachmentDetail'
import { MinimumExhibitorDetail } from './ExhibitorDetail'
import SemaphoreTagDetail from './SemaphoreTagDetail'
import ObjectTypeDetail from './ObjectTypeDetail'
import ContentVisibility from './ContentVisibility'
import SessionTypeDetail from './SessionTypeDetail'
import { LeadVideoDetail, VideoDetail } from './VideoDetail'
import CountryDataReference from './CountryDataReference'
import TagDataReference from './TagDataReference'
import CountryTagDataReference from './CountryTagDataReference'
import Seo from './Seo'

export const MinimumSessionDetail = `
  ${MinimumCreatorDetail}
  ${MinimumExhibitorDetail}
  ${ObjectTypeDetail}
  ${VideoDetail}
  ${ContentVisibility}
  fragment MinimumSessionDetail on SessionDetail {
    conferenceYear
    contentLocale
    creators {
      ...MinimumCreatorDetail
    }
    date
    description
    exhibitors {
      ...MinimumExhibitorDetail
    }
    id
    promo {
      contentType {
        ...ObjectTypeDetail
      }
      __typename
    sessionDifficulty
    sessionFormat
    sessionLocation
    }
    sessionUrl
    title
    url
    video {
      ...VideoDetail
    }
    visibility {
      ...ContentVisibility
    }
  }
`

export const MinDashboardSessionDetail = `
  ${Agreement}
  ${AttachmentDetail}
  ${MinimumCreatorDetail}
  ${MinimumExhibitorDetail}
  ${ObjectTypeDetail}
  ${VideoDetail}
  ${ContentVisibility}
  fragment MinDashboardSessionDetail on SessionDetail {
    agreements {
      ...Agreement
    }
    conferenceYear
    contentLocale
    classSyllabus {
      ...AttachmentDetail
    }
    creators {
      ...MinimumCreatorDetail
    }
    date
    description
    exhibitors {
      ...MinimumExhibitorDetail
    }
    id
    promo {
      contentType {
        ...ObjectTypeDetail
      }
      __typename
    
    sessionDifficulty
    sessionFormat
    sessionLocation
    }
    sessionUrl
    slideDeck {
      ...AttachmentDetail
    }
    title
    url
    video {
      ...VideoDetail
    }
    visibility {
      ...ContentVisibility
    }
  }
`
export const SessionDetail = `
  ${ConferencePromo}
  ${Agreement}
  ${CountryDataReference}
  ${CountryTagDataReference}
  ${CategoryDataReference}
  ${AttachmentDetail}
  ${SemaphoreTagDetail}
  ${ObjectTypeDetail}
  ${SessionTypeDetail}
  ${ContentVisibility}
  ${MinimumCreatorDetail}
  ${MinimumExhibitorDetail}
  ${LeadVideoDetail}
  ${Seo}
  fragment SessionDetail on SessionDetail {
    additionalExperiences {
      ...ConferencePromo
    }
    agreements {
      ...Agreement
    }
    alternateLocales
    categories {
      ...CategoryDataReference
    }
    classroomName
    classSyllabus {
      ...AttachmentDetail
    }
    conferenceYear
    contentLocale
    countries {
      ...CountryDataReference
    }
    countryTags {
      ...CountryTagDataReference
    }
    creators {
      ...MinimumCreatorDetail
    }
    date
    description
    downloadableResources {
      ...AttachmentDetail
    }
    exhibitors {
      ...MinimumExhibitorDetail
    }
    id
    promo {
      ...ConferencePromo
    }
    semaphoreTags {
      ...SemaphoreTagDetail
    }
    seo {
      ...Seo
    }
    sessionDifficulty
    sessionFormat
    sessionId
    sessionLocation
    sessionUrl
    slate
    slideDeck {
      ...AttachmentDetail
    }
    suggestedContent {
      ...ConferencePromo
    }
    title
    typeDetails {
      ...SessionTypeDetail
    }
    url
    video {
      ...LeadVideoDetail
    }
    visibility {
      ...ContentVisibility
    }
  }
`

export const DashboardSessionDetail = `
  ${ConferencePromo}
  ${Agreement}
  ${CountryDataReference}
  ${CountryTagDataReference}
  ${CategoryDataReference}
  ${AttachmentDetail}
  ${SemaphoreTagDetail}
  ${ObjectTypeDetail}
  ${SessionTypeDetail}
  ${TagDataReference}
  ${ContentVisibility}
  ${MinimumCreatorDetail}
  ${MinimumExhibitorDetail}
  ${LeadVideoDetail}
  fragment DashboardSessionDetail on SessionDetail {
    additionalExperiences {
      ...ConferencePromo
    }
    agreements {
      ...Agreement
    }
    alternateLocales
    categories {
      ...CategoryDataReference
    }
    classroomName
    classSyllabus {
      ...AttachmentDetail
    }
    conferenceYear
    contentLocale
    coSpeakers
    countries {
      ...CountryDataReference
    }
    countryTags {
      ...CountryTagDataReference
    }
    creators {
      ...MinimumCreatorDetail
    }
    date
    description
    downloadableResources {
      ...AttachmentDetail
    }
    exhibitors {
      ...MinimumExhibitorDetail
    }
    id
    previousSessionLink
    promo {
      ...ConferencePromo
    }
    semaphoreTags {
      ...SemaphoreTagDetail
    }
    sessionApplication
    sessionDifficulty
    sessionFlexibility
    sessionFormat
    sessionId
    sessionLocation
    sessionOutline
    sessionPrerequisites
    sessionProblem
    sessionTechnology
    sessionUrl
    slate
    slideDeck {
      ...AttachmentDetail
    }
    speakerQualifications
    suggestedContent {
      ...ConferencePromo
    }
    tags {
      ...TagDataReference
    }
    title
    typeDetails {
      ...SessionTypeDetail
    }
    url
    video {
      ...LeadVideoDetail
    }
    visibility {
      ...ContentVisibility
    }
  }
`
