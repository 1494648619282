import { CHAT_ROUTE_KEY } from '@fs/zion-chat'
import { conferenceConfig } from './time'

export const floatingChatConfig = {
  ROUTE_BLACKLIST: [
    '/session/*',
    '/speakers/*',
    '/special-session/:id',
    '/registered-session',
    '/expohall/:venderId',
    '/dashboard',
    '/call-for-presentations',
    '/vendor-linking/:id',
    '/volunteer',
    '/volunteer/*',
  ],
}

export const chatConfig = {
  EVENT_LABEL: `rootstech_${conferenceConfig.year}`,
  COMMUNITY_ROUTE: CHAT_ROUTE_KEY.CHAT,
}
