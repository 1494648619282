import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Bleed } from '@fs/zion-ui'
import { trackLink } from '@fs/zion-analytics'
import { i18n } from '@fs/zion-locale'
import { allSponsorsWebp, allSponsorsJpg } from '../../../images'
import { FLAGS } from '../../../config'
import { useFlagsContext } from '../../../providers'

function SponsorsCard() {
  const [t] = useTranslation()
  const expoHallPrefix =
    window.location.hostname === 'localhost' ? '/expohall/' : `/${i18n.language}/rootstech/expohall/`
  const { [FLAGS.EXPOHALL]: expoHallFlag } = useFlagsContext()

  return (
    <>
      {expoHallFlag.isOn && (
        <Card>
          <Bleed all>
            <img
              src={allSponsorsWebp || allSponsorsJpg}
              alt={t('sponsors.image', 'Sponsors')}
              style={{ height: '100%', width: '100%' }}
              loading="lazy"
              data-testid="sponsor-logos"
            />
            <LinkMap prefix={expoHallPrefix} />
          </Bleed>
        </Card>
      )}
    </>
  )
}

/* eslint-disable */
function LinkMap({ prefix: expoHallPrefix }) {
  const [t] = useTranslation()

  return <>
    <a
      title={t('sponsors.image.familysearch', 'FamilySearch Booth')}
      aria-label={t('sponsors.image.familysearch', 'FamilySearch Booth')}
      href={`${expoHallPrefix}familysearch`}
      data-link-name="Sponsor: FamilySearch"
      style={{ position: 'absolute', insetInlineStart: '36.8%', top: '3%', width: '26.76%', height: '13.2%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.ancestry', 'Ancestry Booth')}
      aria-label={t('sponsors.image.ancestry', 'Ancestry Booth')}
      href={`${expoHallPrefix}ancestry`}
      data-link-name="Sponsor: Ancestry"
      style={{ position: 'absolute', insetInlineStart: '2.3%', top: '20.5%', width: '22.8%', height: '8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.my-heritage', 'My Heritage Booth')}
      aria-label={t('sponsors.image.my-heritage', 'My Heritage Booth')}
      href={`${expoHallPrefix}myheritage`}
      data-link-name="Sponsor: MyHeritage"
      style={{ position: 'absolute', insetInlineStart: '31.2%', top: '20.3%', width: '22.4%', height: '7.8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.storied', 'Storied Booth')}
      aria-label={t('sponsors.image.storied', 'Storied Booth')}
      href={`${expoHallPrefix}storied`}
      data-link-name="Sponsor: Storied"
      style={{ position: 'absolute', insetInlineStart: '60.3%', top: '19.8%', width: '17.3%', height: '7.3%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.find-my-past', 'Find My Past')}
      aria-label={t('sponsors.image.find-my-past', 'Find My Past')}
      href={`${expoHallPrefix}findmypast`}
      data-link-name="Sponsor: Find My Past"
      style={{ position: 'absolute', insetInlineStart: '85%', top: '17.4%', width: '12.5%', height: '12%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.family-tree-dna', 'Family Tree DNA Booth')}
      aria-label={t('sponsors.image.family-tree-dna', 'Family Tree DNA Booth')}
      href={`${expoHallPrefix}familytreedna`}
      data-link-name="Sponsor: FamilyTreeDNA"
      style={{ position: 'absolute', insetInlineStart: '15.8%', top: '33.3%', width: '11.3%', height: '11%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.mylio', 'Mylio Booth')}
      aria-label={t('sponsors.image.mylio', 'Mylio Booth')}
      href={`${expoHallPrefix}mylio`}
      data-link-name="Sponsor: Mylio"
      style={{ position: 'absolute', insetInlineStart: '44.3%', top: '35%', width: '10%', height: '9%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.pictureline', 'Pictureline Booth')}
      aria-label={t('sponsors.image.pictureline', 'Pictureline Booth')}
      href={`${expoHallPrefix}pictureline`}
      data-link-name="Sponsor: pictureline"
      style={{ position: 'absolute', insetInlineStart: '69%', top: '36.9%', width: '15%', height: '4.8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.roots-magic', 'RootsMagic, Inc. Booth')}
      aria-label={t('sponsors.image.roots-magic', 'RootsMagic, Inc. Booth')}
      href={`${expoHallPrefix}rootsmagic`}
      data-link-name="Sponsor: RootsMagic, Inc."
      style={{ position: 'absolute', insetInlineStart: '3.95%', top: '52%', width: '10.2%', height: '10.2%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.family-tree-maker', 'Family Tree Maker Booth')}
      aria-label={t('sponsors.image.family-tree-maker', 'Family Tree Maker Booth')}
      href={`${expoHallPrefix}family-tree-maker`}
      data-link-name="Sponsor: Family Tree Maker"
      style={{ position: 'absolute', insetInlineStart: '20.3%', top: '50.3%', width: '11.9%', height: '13.4%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.gedmatch', 'GEDmatch Booth')}
      aria-label={t('sponsors.image.gedmatch', 'GEDmatch Booth')}
      href={`${expoHallPrefix}gedmatch`}
      data-link-name="Sponsor: GEDmatch"
      style={{ position: 'absolute', insetInlineStart: '38.9%', top: '54%', width: '8.8%', height: '9.1%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.dnangels', 'DNAngels Booth')}
      aria-label={t('sponsors.image.dnangels', 'DNAngels Booth')}
      href={`${expoHallPrefix}dnangels`}
      data-link-name="Sponsor: DNAngels"
      style={{ position: 'absolute', insetInlineStart: '55.3%', top: '50%', width: '7.5%', height: '14.6%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.byu-pathway-worldwide', 'BYU-Pathway Worldwide')}
      aria-label={t('sponsors.image.byu-pathway-worldwide', 'BYU-Pathway Worldwide')}
      href={`${expoHallPrefix}byu-pathway-worldwide`}
      data-link-name="Sponsor: BYU-Pathway Worldwide"
      style={{ position: 'absolute', insetInlineStart: '70%', top: '53.7%', width: '7.9%', height: '9.82%' }}
      onClick={trackLink}
    />
    {
      <a
        title={t('sponsors.image.the-family-history-guide-association', 'The Family History Guide Association Booth')}
        aria-label={t('sponsors.image.the-family-history-guide', 'The Family History Guide Association Booth')}
        href={`${expoHallPrefix}the-family-history-guide-association`}
        data-link-name="Sponsor: The Family History Guide"
        style={{ position: 'absolute', insetInlineStart: '83.8%', top: '55.7%', width: '13%', height: '7.5%' }}
        onClick={trackLink}
      />
    }
    <a
      title={t('sponsors.image.forever-com', 'Forever.com Booth')}
      aria-label={t('sponsors.image.forever-com', 'Forever.com Booth')}
      href={`${expoHallPrefix}forever-com`}
      data-link-name="Sponsor: Forever.com"
      style={{ position: 'absolute', insetInlineStart: '3.95%', top: '67%', width: '11%', height: '4%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.vivid-pix', 'Vivid Pix Booth')}
      aria-label={t('sponsors.image.vivid-pix', 'Vivid Pix Booth')}
      href={`${expoHallPrefix}vivid-pix`}
      data-link-name="Sponsor: Vivid Pix"
      style={{ position: 'absolute', insetInlineStart: '21%', top: '67.0%', width: '10.8%', height: '4%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.dna-painter', 'DNA Painter Booth')}
      aria-label={t('sponsors.image.dna-painter', 'DNA Painter Booth')}
      href={`${expoHallPrefix}dna-painter`}
      data-link-name="Sponsor: DNA Painter"
      style={{ position: 'absolute', insetInlineStart: '37.4%', top: '67.4%', width: '12.1%', height: '3.4%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.fileShadow-inc', 'fileShadow Inc. Booth')}
      aria-label={t('sponsors.image.fileShadow-inc', 'fileShadow Inc. Booth')}
      href={`${expoHallPrefix}fileShadow-inc`}
      data-link-name="Sponsor: fileShadow Inc."
      style={{ position: 'absolute', insetInlineStart: '53.5%', top: '67.1%', width: '11%', height: '4%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.straker', 'Straker Booth')}
      aria-label={t('sponsors.image.straker', 'Straker Booth')}
      href={`${expoHallPrefix}straker`}
      data-link-name="Sponsor: Straker"
      style={{ position: 'absolute', insetInlineStart: '68.5%', top: '66%', width: '10.5%', height: '6.3%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.generations', 'Generations Studio Booth')}
      aria-label={t('sponsors.image.generations', 'Generations Studio Booth')}
      href={`${expoHallPrefix}generations-studio`}
      data-link-name="Sponsor: Generations"
      style={{ position: 'absolute', insetInlineStart: '83.4%', top: '65.74%', width: '13.7%', height: '7%' }}
      onClick={trackLink}
    />

    <a
      title={t('sponsors.image.american-ancestors', 'American Ancestors Booth')}
      aria-label={t('sponsors.image.american-ancestors', 'American Ancestors Booth')}
      href={`${expoHallPrefix}american-ancestors-and-new-england-historic-genealogical-society`}
      data-link-name="Sponsor: American Ancestors"
      style={{ position: 'absolute', insetInlineStart: '2.4%', top: '85.4%', width: '14.7%', height: '5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.association-professional-genealogists', 'Association Professional Genealogists')}
      aria-label={t('sponsors.image.association-professional-genealogists', 'Association Professional Genealogists')}
      href={`${expoHallPrefix}association-of-professional-genealogists`}
      data-link-name="Sponsor: APG"
      style={{ position: 'absolute', insetInlineStart: '19.47%', top: '82.82%', width: '7.5%', height: '10.5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.National-Genealogical-Society', 'National Genealogical Society')}
      aria-label={t('sponsors.image.National-Genealogical-Society', 'National Genealogical Society')}
      href={`${expoHallPrefix}National-Genealogical-Society`}
      data-link-name="Sponsor: National Genealogical Society"
      style={{ position: 'absolute', insetInlineStart: '29.3%', top: '83.6%', width: '15%', height: '7.8%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.church-history-library', 'Church History Library Booth')}
      aria-label={t('sponsors.image.church-history-library', 'Church History Library Booth')}
      href={`${expoHallPrefix}church-history-library`}
      data-link-name="Sponsor: Church History Library"
      style={{ position: 'absolute', insetInlineStart: '46%', top: '84.7%', width: '10%', height: '6.5%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.ten-million-names', '10 Million Names Booth')}
      aria-label={t('sponsors.image.ten-million-names', '10 Million Names Booth')}
      href={`${expoHallPrefix}10-million-names`}
      data-link-name="Sponsor: 10-million-names"
      style={{ position: 'absolute', insetInlineStart: '58.90%', top: '83.5%', width: '10.3%', height: '9.80%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.ny-gen-bio-society', 'New York Genealogical and Biographical Society Booth')}
      aria-label={t('sponsors.image.ny-gen-bio-society', 'New York Genealogical and Biographical Society Booth')}
      href={`${expoHallPrefix}new-york-genealogical-and-biographical-society`}
      data-link-name="Sponsor: NY Genealogical & Biographical Society"
      style={{ position: 'absolute', insetInlineStart: '70.79%', top: '84.6%', width: '8.88%', height: '6.87%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.iajgs', 'International Association of Jewish Genealogical Societies Booth')}
      aria-label={t('sponsors.image.iajgs', 'International Association of Jewish Genealogical Societies Booth')}
      href={`${expoHallPrefix}international-association-of-jewish-genealogical-societies`}
      data-link-name="Sponsor: IAJGS"
      style={{ position: 'absolute', insetInlineStart: '82.33%', top: '81.9%', width: '6%', height: '9.6%' }}
      onClick={trackLink}
    />
    <a
      title={t('sponsors.image.Heritage-Genealogy-College', 'Heritage Genealogy College Booth')}
      aria-label={t('sponsors.image.Heritage-Genealogy-College', 'Heritage Genealogy College Booth')}
      href={`${expoHallPrefix}heritage-genealogical-college`}
      data-link-name="Sponsor: Heritage Genealogy College"
      style={{ position: 'absolute', insetInlineStart: '90.3%', top: '83%', width: '7.5%', height: '9.5%' }}
      onClick={trackLink}
    />
  </>;
}
// /* eslint-enable */

export default SponsorsCard
