import { buildChatRoute, CHAT_ROUTE_KEY } from '@fs/zion-chat'
import React from 'react'
import { i18n } from '@fs/zion-locale'
import { useFeatureFlag } from '@fs/zion-flags'
import { useFlagsContext } from '../../providers'
import ChatButton from '../../components/header/ChatButton'
import { chatConfig, FLAGS, supportConfig } from '../../config'
import { getEnv } from '../helpers'

const getCommunityCID = () => {
  const env = getEnv()
  const chatGroup = {
    integration: 'fs_rootstech:pgfvx53-20220302_conference_chatsvr_familysearch_org',
    beta: 'fs_rootstech:rqnf7rj-20231212101202_J9HB',
    www: 'fs_rootstech:rskd98v-20230224185751_prod-J9HB',
  }
  return chatGroup[env]
}

export default function AUAChatButton({ children, asComponent, ...props }) {
  const { [FLAGS.SITE_EXPERIENCE]: siteExperienceFlag } = useFlagsContext()
  const askUsAnythingFlag = useFeatureFlag('messaging_chat_askUsAnything')
  const userLocale = i18n.language
  const useCommunityChat = !askUsAnythingFlag.isOn && !supportConfig.chatLocales.includes(userLocale)

  const chatRoute =
    siteExperienceFlag.treatment === 'conference' || !useCommunityChat
      ? buildChatRoute(CHAT_ROUTE_KEY.CREATE_SUPPORT_CHAT)
      : buildChatRoute(chatConfig.COMMUNITY_ROUTE, { cid: getCommunityCID() })

  return (
    <ChatButton asComponent={asComponent} chatRoute={chatRoute} {...props}>
      {children}
    </ChatButton>
  )
}
