export const mapExhibitorsToCategory = ({ section, exhibitors }) => {
  if (!exhibitors) {
    return {}
  }

  // put the exhibitors into a map so that we don't have to search an array for each item
  const exhibitorMap = exhibitors.reduce((acc, exhibitor) => {
    acc[exhibitor.id] = exhibitor.exhibitorCategories
    return acc
  }, {})

  const categoryMap = {}
  section.forEach((item) => {
    const { contentId } = item
    const exhibitorCategories = exhibitorMap[contentId] || []

    exhibitorCategories.forEach(({ title }) => {
      if (!categoryMap[title]) {
        categoryMap[title] = []
      }
      categoryMap[title].push(item)
    })
  })

  return categoryMap
}
