/* eslint-disable no-fallthrough */
import React from 'react'
import { css } from '@emotion/core'
import {
  Bleed,
  Ellipsis,
  Image,
  Separator,
  Paragraph,
  Card,
  Row,
  WhiteTheme,
  Subheading,
  colors,
  H3,
  usePageWidth,
} from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { removeHtml } from '@fs/zion-frontend-friends'
import { MenuNewWindow, ContentLock, ContentUnlock } from '@fs/zion-icon'
import { Link } from '@fs/zion-router'
import { getRelativePath } from '../helpers'
import { placeholderSeafoamJpg } from '../../images'
import {
  AcceptedBadge,
  Badge,
  DeclinedBadge,
  DraftBadge,
  EditableBadge,
  LanguageBadge,
  LiveBadge,
  SeriesBadge,
  SubmittedBadge,
} from '../Badge'
import Timestamp from '../Timestamp'
import RainbowDivider from '../RainbowDivider'
import WatchProgressBar from '../WatchProgressBar'
import { StrongLink, PossibleLink } from '../link'
import PlaylistsButton from '../buttons/PlaylistsButton'
import FollowSeriesButton from '../buttons/FollowSeriesButton'
import { sessionStates } from '../../config'

const externalLinkCss = css`
  color: #ffffff !important;
`
const languageBadgeCss = css`
  position: absolute;
  bottom: 16px;
  inset-inline-start: 16px;
`

const durationBadgeCss = css`
  position: absolute;
  bottom: 16px;
  inset-inline-end: 16px;
`

export const ImageBadges = ({ contentLocale, duration }) => {
  const durationItems = duration?.split(':')
  const hours = durationItems?.[2] ?? 0
  const minutes = durationItems?.[1] ?? 0
  const seconds = durationItems?.[0] ?? 0
  const durationIsZero = Number(duration?.replace(':', '')) === 0
  const prettyDuration = duration?.substring(duration.match('[1-9]')?.index ?? 0)

  return (
    <>
      <div css={languageBadgeCss}>
        <LanguageBadge contentLocale={contentLocale} />
      </div>
      {duration && !durationIsZero && (
        <Badge
          flavor="charcoal"
          label={
            <time dateTime={`PT${hours}H${minutes}M${seconds}S`}>
              <WhiteTheme>{prettyDuration}</WhiteTheme>
            </time>
          }
          css={durationBadgeCss}
        />
      )}
    </>
  )
}

export const HeaderBadges = ({ isSeries, isLive, workflowState }) => {
  const hasBadge = isSeries || isLive || (workflowState && workflowState)
  return hasBadge ? (
    <>
      <Row gutters="nano" wrap>
        {isSeries && <SeriesBadge />}
        {isLive && <LiveBadge />}
        {workflowState === sessionStates.DRAFT && <DraftBadge />}
        {workflowState === sessionStates.SUBMITTED && <SubmittedBadge />}
        {workflowState === sessionStates.PUBLISHED && <SubmittedBadge />}
        {workflowState === sessionStates.ACCEPTED && <AcceptedBadge />}
        {workflowState === sessionStates.DECLINED && <DeclinedBadge />}
        {workflowState === sessionStates.EDITABLE && <EditableBadge />}
      </Row>
      <Separator size="xxs" />
    </>
  ) : null
}

export const CardFooterSection = ({
  actions,
  isPlaylistable,
  contentId,
  external,
  heading,
  isSeries,
  linkProps,
  lockedState,
  item,
}) => {
  const atWidth = usePageWidth()
  const { useIconButton, buttonSize } = atWidth({
    default: { useIconButton: true },
    xl: { useIconButton: false, buttonSize: 'sm' },
  })
  return (
    <Row alignY="middle">
      <div growX>{actions}</div>
      {lockedState === 'lock' && <ContentLock background="black" color="white" size="sm" />}
      {lockedState === 'unlock' && <ContentUnlock background="black" color="white" size="sm" />}
      {/* TODO: low emphaiss buttons bleed to the right too much */}
      {isPlaylistable && (
        <>
          {!isSeries && (
            <PlaylistsButton item={item} sessionId={contentId} size={buttonSize} asIconButton={useIconButton} />
          )}
          {isSeries && <FollowSeriesButton seriesId={contentId} size={buttonSize} asIconButton={useIconButton} />}
        </>
      )}
      {external && (
        <Link {...linkProps} linkName="External Link Icon" aria-label={heading}>
          <MenuNewWindow css={externalLinkCss} />
        </Link>
      )}
    </Row>
  )
}

export default function NewCard({
  image = placeholderSeafoamJpg,
  maxImageHeight,
  noImage = false,
  heading,
  subHeading,
  children,
  description,
  // Badges/Buttons
  contentLocale,
  conferenceYear,
  duration,
  startTimestamp,
  isLive,
  lockedState,
  workflowState,
  // ----
  contentId,
  isPlaylistable,
  isInPlaylist,
  'data-testid': dataTestId,
  actions,
  to,
  external,
  flavor,
  noCrop = false,
  percentageWatched = 0,
  force16By9 = false,
  item,
  ...props
}) {
  const [t] = useTranslation()

  const scrubText = (text) => {
    return removeHtml(text)
      ?.replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&')
  }
  const url = external ? to : getRelativePath(to)
  const sessionUrls = ['/session/', '/special-session/', '/registered-session/']
  const isSeries = url?.includes('/series/')
  const isSession = sessionUrls.some((sessionUrl) => url?.includes(sessionUrl))
  const videoUrls = ['/series/', ...sessionUrls]
  const isVideo = force16By9 || videoUrls.some((videoUrl) => url?.includes(videoUrl))
  const linkProps = { to: url, external, target: external ? '_blank' : '_self' }
  const scrubbedHeading = scrubText(heading)

  return (
    <>
      {heading && (
        <Card data-testid="card-all" {...props}>
          {!noImage && (
            <>
              <Bleed top sides>
                <div style={{ position: 'relative' }}>
                  <PossibleLink linkName={`Card: ${scrubbedHeading}: Image`} {...linkProps}>
                    <Image
                      src={image}
                      style={{ backgroundColor: 'white', aspectRatio: isVideo ? '16/9' : '3/2' }}
                      noCrop={noCrop}
                      alt={t('card.image.alt.prefix', 'Thumbnail for {heading}', { heading: scrubbedHeading })}
                      maxHeight={maxImageHeight}
                      loading="lazy"
                    />
                  </PossibleLink>
                  <ImageBadges contentLocale={contentLocale} duration={duration} />
                  {isSession && (
                    <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                      <WatchProgressBar label={scrubbedHeading} percentageWatched={percentageWatched} />
                    </div>
                  )}
                </div>
              </Bleed>
              <Separator size="nano" />
            </>
          )}
          <div growY>
            {(conferenceYear || startTimestamp || isLive) && (
              <>
                <Row gutters="lg">
                  <div growX />
                  <Timestamp
                    alignX="end"
                    timestamp={startTimestamp}
                    conferenceYear={conferenceYear}
                    isActive={isLive}
                  />
                </Row>
                {/* This is equal to a pico separator */}
                <div style={{ height: 4 }} />
              </>
            )}
            <HeaderBadges isSeries={isSeries} isLive={isLive} workflowState={workflowState} />
            <H3 size="H6" data-testid="card-header">
              <WhiteTheme>
                <Ellipsis
                  as={StrongLink}
                  noUnderline
                  linkName={`Card: ${scrubbedHeading}: Title`}
                  {...linkProps}
                  lineClamp={3}
                >
                  {scrubbedHeading}
                </Ellipsis>
              </WhiteTheme>
              <Subheading>
                {subHeading ? <Ellipsis lineClamp={3}>{scrubText(subHeading)}</Ellipsis> : undefined}
              </Subheading>
            </H3>
            {flavor && <RainbowDivider thick and full of flavor={flavor} />}
            {children}
            {description && (
              <>
                <Separator size="nano" />
                <Paragraph size={noImage ? 'md' : 'sm'}>
                  <span style={{ color: colors.gray80 }}>
                    <Ellipsis lineClamp={isSeries ? 1 : 3}>{scrubText(description)}</Ellipsis>
                  </span>
                </Paragraph>
              </>
            )}
          </div>
          <Separator size="xxs" />
          <CardFooterSection
            actions={actions}
            startTimestamp={startTimestamp}
            conferenceYear={conferenceYear}
            isPlaylistable={isPlaylistable}
            isInPlaylist={isInPlaylist}
            contentId={contentId}
            dataTestId={dataTestId}
            external={external}
            heading={heading}
            isLive={isLive}
            isSeries={isSeries}
            linkProps={linkProps}
            lockedState={lockedState}
            item={item}
          />
        </Card>
      )}
    </>
  )
}
