export const usabillaConfig = {
  url: '//w.usabilla.com/1810e28bb08e.js',
  localeMap: {
    ar: 'Arabic',
    de: 'German',
    en: '',
    es: 'Spanish',
    fr: 'French',
    it: 'Italian',
    ja: 'Japan',
    ko: 'Korean',
    pt: 'Portuguese',
    ru: 'Russian',
    zh: 'ChineseTraditional',
    'zh-hans': 'Chinese',
    fj: 'Fijian',
    hu: 'Hungarian',
    id: 'Indonesian',
    kh: 'Khmer',
    mn: 'Mongolian',
    nl: 'Dutch',
    no: 'Norwegian',
    sm: 'Samoan',
    th: 'Thai',
    tl: 'Tagalog',
    to: 'Tongan',
    vi: 'Vietnamese',
  },
}

export const sessionConfig = {
  ...usabillaConfig,
  url: '//w.usabilla.com/c9346265d7c9.js',
}
