import React, { memo } from 'react'
import { Row, useContainerWidth } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { MediaPlay } from '@fs/zion-icon'
import { useFeatureFlag } from '@fs/zion-flags'
import { useTimeTravel } from '../../../providers'
import CardListItem from '../CardListItem'
import Timestamp from '../../Timestamp'
import { LiveBadge, SeriesBadge, DraftBadge, InPersonBadge, SessionDifficultyBadge, OnlineBadge } from '../../Badge'
import {
  getIsLive,
  isSeriesConferencePromo,
  isDraft,
  getRelativePath,
  getCustomImage,
  isSessionConferencePromo,
  shouldShowActionButton,
} from '../../helpers'
import { PillButton, PlaylistsButton, FollowSeriesButton, SocialShareButton } from '../../buttons'
import { getIsPlaylistable } from '../../../hooks'
import { conferenceConfig } from '../../../config'

const Badges = ({ isLive, isSeries, isDraftSession, promo, ...props }) => {
  const location = promo?.sessionLocation
  return (
    <Row gutters="nano" {...props}>
      {isLive && <LiveBadge />}
      {isSeries && <SeriesBadge />}
      {isDraftSession && <DraftBadge />}
      {location?.includes('In Person') && <InPersonBadge />}
      {location?.includes('Online') && <OnlineBadge />}
      {promo?.sessionDifficulty && <SessionDifficultyBadge sessionDifficulty={promo?.sessionDifficulty} />}
    </Row>
  )
}

const Actions = ({ to, data, linkPage, isSeries, asIconButton, isInPerson }) => {
  const [t] = useTranslation()
  const scheduleFlag = useFeatureFlag('rootstech_schedule')
  const { getNow } = useTimeTravel()
  const shouldShowButton = shouldShowActionButton(data, conferenceConfig.year, getNow)
  const { title, contentId, url, description, id, promo, contentType, conferenceYear } = data
  const thumbnail = getCustomImage(data)
  const isPlaylistable = isInPerson
    ? false
    : getIsPlaylistable({
        contentType: promo?.contentType || contentType,
      })

  return (
    <Row alignX={isInPerson ? 'end' : 'start'}>
      {(!isInPerson && conferenceYear !== conferenceConfig.year) || (scheduleFlag.isOn && shouldShowButton) ? (
        <PillButton
          growX
          emphasis="medium"
          to={to}
          Icon={MediaPlay}
          data-testid="list-item-watch"
          linkName={`Watch: ${title}: Button`}
        >
          {t('series.watch.action', 'Watch')}
        </PillButton>
      ) : (
        <div growX />
      )}

      {/* TODO: Add views when supported */}
      <Row gutters="md">
        {isPlaylistable && !isSeries && (
          <PlaylistsButton sessionId={contentId || id} asIconButton={asIconButton} emphasis="low" item={data} />
        )}
        {isPlaylistable && isSeries && <FollowSeriesButton seriesId={contentId || id} />}
        <SocialShareButton
          title={title}
          linkPage={linkPage}
          asIconButton={asIconButton}
          url={url}
          thumbnail={thumbnail}
          description={description}
          size={asIconButton ? 'md' : 'lg'}
        />
      </Row>
    </Row>
  )
}

function SessionCard({ data, linkPage }) {
  const atWidth = useContainerWidth()

  const { conferenceYear, date, title, description, creators, exhibitors, url, promo = {}, video } = data
  const { videoDuration } = video || {}
  const isInPerson = promo?.sessionLocation === 'In Person'

  const isLive = getIsLive({ date, videoDuration })
  const isSeries = isSeriesConferencePromo(promo)
  const isDraftSession = isDraft(data)
  const to = getRelativePath(url)
  const asIconButton = atWidth({ default: true, xl: false })
  const isSession = isSessionConferencePromo(promo)
  const linkName = `Watch: ${title}: Title`
  const videoData = isInPerson ? null : { ...data, isSession, thumbnailLinkName: `Watch: ${title}: Thumbnail` }

  return (
    <CardListItem
      badges={<Badges isLive={isLive} isSeries={isSeries} promo={promo} isDraftSession={isDraftSession} />}
      timestamp={<Timestamp conferenceYear={conferenceYear} timestamp={date} />}
      heading={title}
      linkName={linkName}
      description={description}
      creators={creators}
      exhibitors={exhibitors}
      to={to}
      dataForVideo={videoData}
      actions={
        <Actions
          to={to}
          data={data}
          linkPage={linkPage}
          isSeries={isSeries}
          asIconButton={asIconButton}
          isInPerson={isInPerson}
        />
      }
    />
  )
}

export default memo(SessionCard)
