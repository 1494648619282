import ContentVisibility from './ContentVisibility'
import {
  LeadProfileImage,
  LeadPromoImage,
  LeadThumbnailImage,
  ProfileImage,
  PromoImage,
  ThumbnailImage,
} from './ImageDetail'
import ObjectTypeDetail from './ObjectTypeDetail'
import { LeadVideoDetail, VideoDetail } from './VideoDetail'

// NOTE: this is in here since the CreatorDetail file points to this file creating a circular dependency.
export const MinimumCreatorDetail = `
  ${ProfileImage}
  ${ContentVisibility}
  fragment MinimumCreatorDetail on CreatorDetail {
    creatorType
    contentLocale
    id
    name
    biography
    sourceId
    url
    profileImage {
      ...ProfileImage
    }
    visibility {
      ...ContentVisibility
    }
  }
`

// NOTE: this is in here since the CreatorDetail file points to this file creating a circular dependency.
export const LeadMinimumCreatorDetail = `
  ${LeadProfileImage}
  fragment LeadMinimumCreatorDetail on CreatorDetail {
    creatorType
    contentLocale
    id
    name
    biography
    url
    profileImage {
      ...LeadProfileImage
    }
  }
`

export const LeadConferencePromo = `
  ${LeadThumbnailImage}
  ${LeadPromoImage}
  ${ObjectTypeDetail}
  ${LeadMinimumCreatorDetail}
  ${LeadVideoDetail}
  fragment LeadConferencePromo on ConferencePromo {
    conferenceYear
    contentId
    contentLocale
    contentType {
      ...ObjectTypeDetail
    }
    creators {
      ...LeadMinimumCreatorDetail
    }
    ctaText
    date
    description
    image {
      ...LeadPromoImage
    }
    overlineText
    title
    thumbnail {
      ...LeadThumbnailImage
    }
    url
    video {
      ...LeadVideoDetail
    }
    videoDuration
  }
`

export const ConferencePromo = `
  ${ThumbnailImage}
  ${PromoImage}
  ${ObjectTypeDetail}
  ${MinimumCreatorDetail}
  ${VideoDetail}
  fragment ConferencePromo on ConferencePromo {
    classroomName
    conferenceYear
    contentId
    contentLocale
    contentType {
      ...ObjectTypeDetail
    }
    creators {
      ...MinimumCreatorDetail
    }
    ctaText
    date
    description
    image {
      ...PromoImage
    }
    overlineText
    promoId
    sessionDifficulty
    sessionFormat
    sessionLocation
    title
    thumbnail {
      ...ThumbnailImage
    }
    url
    video {
      ...VideoDetail
    }
    videoDuration
  }
`
