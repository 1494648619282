const languageConfig = {
  // Used to load video streams in the correct language
  iso639Conversions: {
    // ISO 639-1: ISO 639-2
    da: 'dan',
    de: 'deu',
    en: 'eng',
    es: 'spa',
    fj: 'fij',
    fr: 'fra',
    hu: 'hun',
    id: 'ind',
    it: 'ita',
    ja: 'jpn',
    km: 'khm',
    ko: 'kor',
    mn: 'mon',
    nl: 'nld',
    no: 'nor',
    pt: 'por',
    ru: 'rus',
    sm: 'smo',
    th: 'tha',
    tl: 'tgl',
    to: 'ton',
    vi: 'vie',
    zh: 'zho',
    'zh-HK': 'yue',
  },
  // Configured on the server to download the correct language pack.
  additionalLangs: ['ar', 'zh-hans', 'fj', 'hu', 'id', 'km', 'mn', 'nl', 'no', 'sm', 'th', 'tl', 'to', 'vi'],
  chineseLocales: ['zh-CN', 'zh-HK', 'zh-TW'],
  chineseLangMapping: {
    'zh-CN': 'zh-hans',
    'zh-HK': 'zh',
    'zh-TW': 'zh',
  },
  complexSiteLanguages: ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'zh', 'zh-hans'],
  languageTranslations: {
    pon: 'Mahsen en Pohnpei',
    tl: 'Tagalog',
    fj: 'Viti',
  },
}

export default languageConfig
