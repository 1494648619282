import { useEffect } from 'react'
import { useFeatureFlag } from '@fs/zion-flags'
import { i18n } from '@fs/zion-locale'
import useLocalStorage from './useLocalStorage'
import { FLAGS } from '../config'
import externalNavigationConfig from '../config/externalNavigation'

export default function useExternalLinkHandler(overlay, setExternalUrl, setMessage) {
  const { doNotShowAgain } = useExternalNavigationHook()
  const externalNavOverlayFlag = useFeatureFlag(FLAGS.EXTERNAL_NAVIGATION_OVERLAY)
  useEffect(() => {
    if (!externalNavOverlayFlag.isOn) {
      return undefined
    }
    const handleClick = (event) => {
      const link = event.target.closest('a')
      if (link?.href && !link.href.includes(window.location.origin)) {
        const url = new URL(link.href)
        const domain = url.hostname.replace('www.', '')

        if (!externalNavigationConfig.allowedDomains.includes(domain)) return

        if (doNotShowAgain) {
          // Allow the link's default behavior to determine if it opens in `_blank`
          return
        }
        // Prevent default navigation and show the overlay
        event.preventDefault()
        overlay.handleClick(event)
        setExternalUrl(link.href)

        // Set custom message if available
        setMessage(
          externalNavigationConfig.messages[domain] ||
            i18n.t('external.navigation.overlay.message.default', 'You are about to visit { domain }.', { domain })
        )
      }
    }

    document.body.addEventListener('click', handleClick)
    return () => document.body.removeEventListener('click', handleClick)
  }, [doNotShowAgain, overlay, setExternalUrl, setMessage, externalNavOverlayFlag.isOn])
}

export function useExternalNavigationHook() {
  // State to track whether the overlay should be shown again
  const [doNotShowAgain, setDoNotShowAgain] = useLocalStorage('doNotShowExternalNavigation', false)

  return { doNotShowAgain, setDoNotShowAgain }
}
