import React from 'react'
import { Button, Checkbox, DialogOverlay, DialogOverlayActions, Paragraph, Row } from '@fs/zion-ui'
import { Trans, useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PillButton } from '../../lib'
import { useExternalNavigationHook } from '../../hooks/useExternalLinkHandler'

const ExternalNavigationOverlay = ({ linkUrl, onConfirm, overlay, customMessage }) => {
  const [t] = useTranslation()
  const { doNotShowAgain, setDoNotShowAgain } = useExternalNavigationHook()

  const handleLeave = () => {
    // Save the "Do not show again" state when leaving
    onConfirm(linkUrl)
  }

  const message = customMessage || (
    <Trans
      i18nKey="external.navigation.overlay.message"
      defaults="You have clicked on a link that will take you away from RootsTech.org to go to <strong>{{linkUrl}}</strong>. Do you wish to continue?"
      components={{ strong: <strong /> }}
      values={{ linkUrl }}
    />
  )

  return (
    <DialogOverlay
      {...overlay}
      title={t('external.navigation.overlay.title', 'Leaving RootsTech')}
      size="lg"
      footer={
        <DialogOverlayActions>
          <Button onClick={overlay.close}>{t('external.navigation.overlay.cancel', 'Cancel')}</Button>
          <PillButton type="submit" emphasis="high" onClick={handleLeave}>
            {t('external.navigation.overlay.leave', 'Leave')}
          </PillButton>
        </DialogOverlayActions>
      }
    >
      <Row>
        <Paragraph size="sm"> {message}</Paragraph>
      </Row>
      <Row>
        <Checkbox
          label={t('external.navigation.overlay.do-not-show-again', 'Do not show this message again.')}
          name="check"
          checked={doNotShowAgain}
          onChange={(e) => setDoNotShowAgain(e.target.checked)}
        />
      </Row>
    </DialogOverlay>
  )
}

ExternalNavigationOverlay.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  overlay: PropTypes.shape({
    close: PropTypes.func.isRequired,
  }).isRequired,
}

export default ExternalNavigationOverlay
