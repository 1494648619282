import { ConferencePromo, LeadConferencePromo } from './ConferencePromo'

export const ListModule = `
  ${ConferencePromo}
  fragment ListModule on ListModule {
    ctaLabel
    ctaTarget
    ctaText
    ctaUrl
    description
    items {
      ...ConferencePromo
    }
    secondaryTitle
    title
    _style {
      _styleVariation {
        _styledTemplate
      }
    }
  }
`
export const LeadListModule = `
  ${LeadConferencePromo}
  fragment LeadListModule on ListModule {
    ctaLabel
    ctaTarget
    ctaText
    ctaUrl
    description
    items {
      ...LeadConferencePromo
    }
    secondaryTitle
    title
  }
`
