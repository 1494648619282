import { css } from '@emotion/core'
import React from 'react'
import { SocialChat } from '@fs/zion-icon'
import { IconButton } from '@fs/zion-ui'
import { useTranslation } from 'react-i18next'
import { useFeatureFlag } from '@fs/zion-flags'
import { useRouteMatch } from '@fs/zion-router'
import { boolAttr } from '@fs/zion-frontend-friends'
import AUAChatButton from '../AUAChatButton'
import PillButton from '../PillButton'
import { useIsMdWidth } from '../../../hooks'
import { floatingChatConfig } from '../../../config'
import { useChatContext } from '../../../providers'

const floatingCss = css`
  position: fixed;
  inset-inline-end: 30px;
  bottom: 10%;
  z-index: 1;
`
const FloatingChatButton = () => {
  const [t] = useTranslation()
  const chatFlag = useFeatureFlag('rootstech_chat')
  const isMdWidth = useIsMdWidth()
  const blacklistMatch = useRouteMatch(floatingChatConfig.ROUTE_BLACKLIST)
  const { isChatApproved } = useChatContext()

  if (blacklistMatch || !isChatApproved) return null

  return (
    chatFlag.isOn && (
      <div css={floatingCss} is-md-width={boolAttr(isMdWidth)}>
        <AUAChatButton
          asComponent={isMdWidth ? IconButton : PillButton}
          color="blue"
          Icon={SocialChat}
          emphasis="high"
          size={isMdWidth ? 'md' : 'lg'}
        >
          {t('chat.button', 'Chat')}
        </AUAChatButton>
      </div>
    )
  )
}

export default FloatingChatButton
