import { i18n } from '@fs/zion-locale'

const externalNavigationConfig = {
  allowedDomains: ['facebook.com', 'x.com', 'instagram.com', 'youtube.com'],
  messages: {
    'facebook.com': i18n.t(
      'external.navigation.overlay.message.facebook',
      'You are leaving RootsTech.org to visit Facebook.'
    ),
    'x.com': i18n.t('external.navigation.overlay.message.x', 'You are about to visit X.'),
    'instagram.com': i18n.t('external.navigation.overlay.message.instagram', 'You are about to visit Instagram.'),
    'youtube.com': i18n.t(
      'external.navigation.overlay.message.youtube',
      'You are leaving RootsTech.org to visit YouTube.'
    ),
  },
}

export default externalNavigationConfig
