import React, { lazy, Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@fs/zion-ui'
import WebinarPlayer from '../WebinarPlayer'
import ErrorPlaceholder from '../ErrorPlaceholder'
import VideoCountdown from '../VideoCountdown'
import VideoPlayerFallback from '../VideoPlayerFallback'
import getBrightcoveIds from './helpers'
import { useWatchEvent, useError } from './hooks'

const YoutubePlayer = lazy(() => import('../YoutubePlayer'))
const BrightcovePlayer = lazy(() => import('../BrightcovePlayer'))

const SUPPORTED_PROVIDERS = ['youtube', 'brightcove', 'WEBINAR', 'countdown']

const LoadingVideo = () => {
  return (
    <div style={{ aspectRatio: '16 / 9' }}>
      <Skeleton.Image height="100%" width="100%" />
    </div>
  )
}

export default function VideoProvider({
  autoplay,
  videoProvider,
  seekStartTime,
  title,
  thumbnail,
  disableControls,
  onEnd,
  loading,
  countdownStartTime,
  isLive,
  sessionId,
  contentLocale,
  videoStartTime,
}) {
  const [t] = useTranslation()
  const [isError, onError] = useError(videoProvider)

  const videoProviderType = videoProvider?.providerType
  const { videoId: brightcoveVideoId, accountId: brightcoveAccountId } = getBrightcoveIds(videoProvider, isLive)
  const videoId = videoProvider?.videoId

  const { trackHistoryEvent, setVideoPlayer } = useWatchEvent({
    videoProviderType,
    sessionId,
    startTime: videoStartTime / 1000,
  })

  useEffect(() => {
    if (videoProviderType === 'brightcove') {
      if (!brightcoveVideoId) {
        onError('No brightcove video ID')
      }
      if (!brightcoveAccountId) {
        onError('No brightcove account ID')
      }
    }
    if (videoProviderType === 'youtube' && !videoId) {
      onError('No youtube video ID')
    }
  }, [brightcoveAccountId, brightcoveVideoId, onError, videoId, videoProviderType])

  const onVideoEnd = (event) => {
    trackHistoryEvent(event, true)
    onEnd?.()
  }
  const onVideoPause = (event) => {
    trackHistoryEvent(event)
  }
  const onReady = (player) => {
    setVideoPlayer(player)
  }
  const onBitmovinError = (cause) => {
    onError(cause, 'Internal error from the Bitmovin player')
  }
  const onYoutubeError = (cause) => {
    console.info(
      'Check the Youtube Player API - onError docs for more details: https://developers.google.com/youtube/iframe_api_reference#Events'
    )
    onError(cause, 'Internal error from the Youtube player')
  }

  if (loading) {
    return <LoadingVideo />
  }
  if (isError) {
    return <ErrorPlaceholder />
  }
  if (videoProviderType === 'brightcove') {
    return (
      <div data-testid="video-player">
        <Suspense fallback={<span />}>
          <BrightcovePlayer
            autoplay={autoplay}
            contentLocale={contentLocale}
            seekStartTime={seekStartTime}
            videoId={brightcoveVideoId}
            accountId={brightcoveAccountId}
            title={title}
            thumbnail={thumbnail}
            onError={onBitmovinError}
            onEnd={onVideoEnd}
            onPause={onVideoPause}
            onStart={onReady}
          />
        </Suspense>
      </div>
    )
  }
  if (videoProviderType === 'youtube') {
    return (
      <div data-testid="video-player">
        <Suspense fallback={<span />}>
          <YoutubePlayer
            videoId={videoId}
            title={title}
            autoplay={autoplay}
            start={seekStartTime}
            disableControls={disableControls}
            onError={onYoutubeError}
            onEnd={onVideoEnd}
            onPause={onVideoPause}
            onReady={onReady}
            isLive={isLive}
          />
        </Suspense>
      </div>
    )
  }
  if (videoProviderType?.toLowerCase() === 'webinar') {
    return <WebinarPlayer thumbnail={thumbnail} url={videoProvider?.url?.trim()} />
  }
  if (videoProviderType === 'countdown') {
    return <VideoCountdown startTimestamp={countdownStartTime} />
  }

  return (
    <VideoPlayerFallback heading={t('home.video-fallback.heading', 'You can browse videos in the on-demand section')} />
  )
}

VideoProvider.propTypes = {
  videoProvider: PropTypes.shape({
    /** The Content Video Provider */
    providerType: PropTypes.oneOf(SUPPORTED_PROVIDERS),
    /** Brightcove and Youtube use this */
    videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Only used for Brightcove */
    accountId: PropTypes.string,
    /** Webinar uses this */
    url: PropTypes.string,
    /** Brightcove player thumbnail */
  }),
  /** HTML title for the content */
  title: PropTypes.string,
  /** Seeks the start time for the video */
  seekStartTime: PropTypes.number,
  /** The Placeholder Thumbnail for Brightcove or Webinar players */
  thumbnail: PropTypes.string,
}
