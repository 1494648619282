import React from 'react'
import { useUser } from '@fs/zion-user'
import { ChatOverlayButton, useChatOverlay } from '@fs/zion-chat'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ChatLoginButton from './ChatLoginButton'

const overlayButtonHackCss = css`
  & > * {
    height: auto;
  }
  & [class*='dotCss_'] {
    top: 8px;
  }
`

const ChatButton = ({ ariaLabel, children, asComponent, chatRoute, ...props }) => {
  const user = useUser()
  const [t] = useTranslation()
  const chatOverlay = useChatOverlay(chatRoute)
  if (!user.signedIn && !user.userLoading)
    return (
      <ChatLoginButton asComponent={asComponent} ariaLabel={ariaLabel} chatRoute={chatRoute ?? 'default'} {...props}>
        {children}
      </ChatLoginButton>
    )

  const Tag = asComponent || ChatOverlayButton

  return (
    <div css={overlayButtonHackCss}>
      <Tag
        onClick={chatOverlay.handleClick}
        {...props}
        aria-label={t('logged-out.reason2', 'Chat with industry experts and other attendees')}
      >
        {children}
      </Tag>
    </div>
  )
}
ChatButton.propTypes = {
  ariaLabel: PropTypes.string,
  asComponent: PropTypes.elementType,
  chatRoute: PropTypes.string,
}

export default ChatButton
