const relativesConfig = {
  eventIds: {
    INT: 'cabf8cce-5788-4429-af0f-a1bb6ad3fe44',
    BETA: '8ceedfe4-9720-4292-be42-72f9e1da842d',
    PROD: '902d49a6-414c-49ad-8370-4129535e9e83',
  },
}
relativesConfig.previewUrl = `https://www.familysearch.org/en/connect/${relativesConfig.eventIds.PROD}`

export default relativesConfig
