import React, { Suspense, lazy } from 'react'
import { Divider, Separator, Skeleton } from '@fs/zion-ui'
import ErrorBoundary from '@fs/zion-error-boundary'
import { Global, css } from '@emotion/core'
import { useFeatureFlag } from '@fs/zion-flags'
import { PageTitle } from './components'
import Feedback from './Feedback'
import { useIsMdWidth } from './hooks'
import { ErrorFallback, FloatingChatButton } from './lib'

const NavBar = lazy(() => import('./components/header/NavBar'))
const AppRoutes = lazy(() => import('./AppRoutes'))
const AppProviders = lazy(() => import('./AppProviders'))
const QAInfoSheets = lazy(() => import('./lib/qa/QAInfoSheets'))
/**
 * As of Dec 11, 2023
 *
 * If we want to change fonts, look at:
 * https://github.com/fs-webdev/zion/blob/master/packages/ui-fonts/src/loadFonts.js
 *
 * We would need to basically copy their implementation to get fonts from foundry
 */

const headingOverrideCss = css`
  body {
    --font-families-headings: var(--font-families-body);
  }
`
const InitialSkeleton = () => {
  const isMdWidth = useIsMdWidth()
  return (
    <div styles={{ background: 'black' }}>
      {/* Nav Bar */}
      <Skeleton.Image width="100%" height={72} />
      <Divider />
      {/* Header */}
      <Skeleton.Image height={isMdWidth ? 400 : 680} width="100%" />
    </div>
  )
}

export default function App() {
  const qaOptionsFlag = useFeatureFlag('rootstech_qa_options')

  return (
    <>
      <PageTitle />
      <Global styles={headingOverrideCss} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppProviders>
          <Suspense fallback={<InitialSkeleton />}>
            <NavBar />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <AppRoutes />
            </ErrorBoundary>
            <Separator size="xxl" />
            <FloatingChatButton />
            {qaOptionsFlag.isOn && <QAInfoSheets />}
          </Suspense>
        </AppProviders>
      </ErrorBoundary>
      <Feedback />
    </>
  )
}
