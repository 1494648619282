import React, { useState } from 'react'
import { useOverlay } from '@fs/zion-ui'
import { useFeatureFlag } from '@fs/zion-flags'
import ExternalNavigationOverlay from './ExternalNavigationOverlay'
import useExternalLinkHandler from '../../hooks/useExternalLinkHandler'
import { FLAGS } from '../../config'

export default function ExternalNavigationManager() {
  const externalNavOverlayFlag = useFeatureFlag(FLAGS.EXTERNAL_NAVIGATION_OVERLAY)
  const overlay = useOverlay()
  const [externalUrl, setExternalUrl] = useState('')
  const [customMessage, setCustomMessage] = useState('')

  useExternalLinkHandler(overlay, setExternalUrl, setCustomMessage)

  const handleConfirm = (url) => {
    window.open(url, '_blank')
    overlay.close()
  }

  return (
    externalNavOverlayFlag.isOn &&
    (overlay.isOpen || overlay.transitioning) && (
      <ExternalNavigationOverlay
        linkUrl={externalUrl}
        onConfirm={handleConfirm}
        overlay={overlay}
        customMessage={customMessage}
      />
    )
  )
}
